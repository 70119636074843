import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HowWeWork from './pages/HowWeWork';
import OurPeople from './pages/OurPeople';
import Testimonials from './pages/testimonials';
import About from './pages/About';
import Homepage from './pages/Homepage';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <div className='App'>
      <Header />
      <Router>
        <Routes>
          <Route exact path='/' element={<Homepage />} />
          <Route exact path='how-we-work' element={<HowWeWork />} />
          <Route exact path='our-people' element={<OurPeople />} />
          <Route exact path='testimonials' element={<Testimonials />} />
          <Route exact path='about' element={<About />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
