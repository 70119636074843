import {
  BoltIcon,
  PlusIcon,
  WrenchIcon,
  ScaleIcon
} from '@heroicons/react/24/outline';

const features = [
  {
    name: 'Seamless Work',
    description:
      'We work seamlessly as part of our clients sales and marketing team to develop compelling messaging and to identify qualified sales opportunities with C’level decision makers. ',
    icon: WrenchIcon
  },
  {
    name: 'Genuine leads',
    description:
      'We engage with prospects with genuine purpose, using all methods of communication available, ensuring best practice in line with GDPR compliance requirements.',
    icon: ScaleIcon
  },
  {
    name: 'Key Growth',
    description:
      'Our sole purpose is to help our clients grow their business and for Business Development and Sales Managers to achieve and exceed sales targets. ',
    icon: BoltIcon
  },
  {
    name: 'Intergrated Services',
    description:
      'We offer a comprehensive range of integrated services, from data sourcing, research, digital marketing, telemarketing, prospect management and tailored messaging to ensure our clients are continually working on a healthy, qualified sales pipeline.',
    icon: PlusIcon
  }
];

export default function Features() {
  return (
    <div className='bg-white py-24 sm:py-32 lg:py-20'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='sm:text-center'>
          <h2 className='text-lg font-semibold leading-8 text-red-600'>
            Transactions
          </h2>
          <p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
            A better way to send money
          </p>
          <p className='mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600'>
            Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam
            voluptatum cupiditate veritatis in accusamus quisquam.
          </p>
        </div>

        <div className='mt-20 max-w-lg sm:mx-auto md:max-w-none'>
          <div className='grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16'>
            {features.map((feature) => (
              <div
                key={feature.name}
                className='relative flex flex-col gap-6 sm:flex-row md:flex-col lg:flex-row'
              >
                <div className='flex h-12 w-12 items-center justify-center rounded-xl bg-red-500 text-white sm:shrink-0'>
                  <feature.icon className='h-8 w-8' aria-hidden='true' />
                </div>
                <div className='sm:min-w-0 sm:flex-1'>
                  <p className='text-lg font-semibold leading-8 text-gray-900'>
                    {feature.name}
                  </p>
                  <p className='mt-2 text-base leading-7 text-gray-600'>
                    {feature.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
