export default function ContentSection({ title, subTitle, isAbout }) {
  return (
    <div className='relative bg-white'>
      <div className='lg:absolute lg:inset-0'>
        <div className='lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2'>
          <img
            className='h-56 w-full object-cover lg:absolute lg:h-full'
            src='https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1567&q=80'
            alt=''
          />
        </div>
      </div>
      <div className='relative px-4 pt-12 pb-16 sm:px-6 sm:pt-16 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-8'>
        <div className='lg:col-start-2 lg:pl-8'>
          <div className='mx-auto max-w-prose text-base lg:ml-auto lg:mr-0 lg:max-w-lg'>
            <h2 className='font-semibold leading-6 text-red-600'>{title}</h2>
            <h3 className='mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl'>
              {subTitle}
            </h3>
            {isAbout && (
              <>
                <p className='mt-8 text-lg text-gray-500'>
                  We are not just a telemarketing company. We are a small team
                  of true Business Development experts with a wealth of
                  experience of New Business Sales within the Corporate Market.
                  Our experience covers high end Consultancy, Technology,
                  Outsourcing and Professional Services and we have an
                  unrivalled history of achieving results across most vertical
                  and horizontal sectors:
                </p>
                <div className='prose prose-red mt-5 text-gray-500'>
                  <ul className='mb-4'>
                    <li>Manufacturing</li>
                    <li>Banking & Finance</li>
                    <li>Retail and Consumer Goods</li>
                    <li>Insurance</li>
                    <li>Public Sector</li>
                    <li>Energy & Utilities</li>
                    <li>Transport</li>
                    <li>Aerospace</li>
                    <li>Pharmaceuticals</li>
                    <li>Legal</li>
                  </ul>
                  <p>
                    We work with our clients to identify the organisations that
                    they wish to engage with and then develop strategies to
                    deliver selling opportunities. We have been doing this
                    successfully for many of the worlds leading companies for
                    over 15 years
                  </p>
                </div>
                <div className='prose prose-red mt-5 text-gray-500'>
                  So why not{' '}
                  <a href='mailto:ian.carr@imcpartnership.co.uk'>contact us</a>{' '}
                  today!
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
