export default function OurPeople() {
  return (
    <div className='relative overflow-hidden bg-white py-16'>
      <div className='hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]'>
        <div
          className='relative mx-auto h-full max-w-prose text-lg'
          aria-hidden='true'
        >
          <svg
            className='absolute top-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='74b3fd99-0a6f-4271-bef2-e80eeafdf357'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)'
            />
          </svg>
          <svg
            className='absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='f210dbf6-a58d-4871-961e-36d5016a0f49'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#f210dbf6-a58d-4871-961e-36d5016a0f49)'
            />
          </svg>
          <svg
            className='absolute bottom-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='d3eb07ae-5182-43e6-857d-35c643af9034'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#d3eb07ae-5182-43e6-857d-35c643af9034)'
            />
          </svg>
        </div>
      </div>
      <div className='relative px-4 sm:px-6 lg:px-8'>
        <div className='mx-auto max-w-prose text-lg'>
          <h1>
            <span className='block text-center text-lg font-semibold text-red-600'>
              IMC
            </span>
            <span className='mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl'>
              Our People
            </span>
          </h1>
          <p className='mt-8 text-xl leading-8 text-gray-500'>
            We are a small team of New Business Development specialists who have
            outstanding track records of identifying, engaging and selling to
            prospects in corporate and public sector environments. We have
            worked in the technology, consulting and outsourcing sectors for
            many years and have a unique expertise in gaining access to
            stakeholders of ‘big ticket’ budgets.
          </p>
        </div>
        <div className='prose prose-lg prose-red mx-auto mt-6 text-gray-500'>
          <p>
            Our people are mature and credible communicators who work alongside
            our clients to develop tailored sales strategies to open negotiation
            discussions with highly targeted prospects. We research industry and
            corporate business drivers to identify where a clients sales
            offerings would be advantageous to the target audience and develop
            appropriate means of communication.
          </p>

          <blockquote>
            <p>
              For some time now, IMC have been collaborating and delivering
              successful CXO engagement projects across a variety of industries.
              In that time they have demonstrated clear understanding of our
              strategic objectives and have been a great asset to help us
              meeting these.
            </p>
          </blockquote>

          <figure>
            <img
              className='w-full rounded-lg'
              src='https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&w=1310&h=873&q=80&facepad=3'
              alt=''
              width={1310}
              height={873}
            />
            <figcaption>
              Leo Curran - Senior Vice President, Head of UK/Europe, EXL
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
}
