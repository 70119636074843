import ContentSection from '../components/ContentSection';

const About = () => {
  return (
    <>
      <ContentSection title='IMC test' subTitle='Who we are' isAbout />
    </>
  );
};

export default About;
