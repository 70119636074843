import logo from '../logo.png';

const navigation = [
  { name: 'About', href: '/about' },
  { name: 'How we work', href: '/how-we-work' },
  { name: 'Our people', href: '/our-people' },
  { name: 'Testimonials', href: '/testimonials' }
];

console.log(logo);

export default function Header() {
  return (
    <header className='bg-red-600'>
      <nav className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8' aria-label='Top'>
        <div className='flex w-full items-center justify-between border-b border-red-500 py-6 lg:border-none'>
          <div className='flex items-center'>
            <a href='/'>
              <span className='sr-only'>IMC telemarketing</span>
              <img className='h-20 w-auto' src={logo} alt='' />
            </a>
            <div className='ml-10 hidden space-x-8 lg:block'>
              {navigation.map((link) => (
                <a
                  key={link.name}
                  href={link.href}
                  className='text-base font-medium text-white hover:text-red-50'
                >
                  {link.name}
                </a>
              ))}
            </div>
          </div>
          <div className='ml-10 space-x-4'>
            <a
              href='mailto:ian.carr@imcpartnership.co.uk'
              className='inline-block rounded-md border border-transparent bg-white py-2 px-4 text-black font-medium text-white hover:bg-opacity-75'
            >
              Contact Us
            </a>
          </div>
        </div>
        <div className='flex flex-wrap justify-center space-x-6 py-4 lg:hidden'>
          {navigation.map((link) => (
            <a
              key={link.name}
              href={link.href}
              className='text-base font-medium text-white hover:text-red-50'
            >
              {link.name}
            </a>
          ))}
        </div>
      </nav>
    </header>
  );
}
