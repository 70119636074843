export const TestimonialCopy = [
  {
    copy: `I have known Ian and IMC for over a decade. He is always a pleasure to work with and his can-do approach to business is both refreshing and infectious. 

    As far as I’m concerned, Ian and IMC are the go-to agency for getting meetings with targets – particularly blue-chip businesses where there are tricky gatekeepers protecting and insulating decision makers. Of all of the agencies that I’ve worked with and come across, IMC has produced by far the best results and ROI.
    
    The proof is always in the pudding, and the sales campaigns that IMC have helped me with over the years have not only made a significant difference to Boyes Turner but also won many awards and are used as best practice across professional services. Personally, I wouldn’t go anywhere else. `,
    heading: `Helping Professional services firms stand out and win business`
  },
  {
    copy: `There are very few people I have met who have the ability to summon enthusiasm in other people for future meetings the way IMC can. They really get what their clients are about and how to communicate their sales message to prospects in a professional, ethical and effective way. A delight to work with and the best in the business.`,
    heading: `Lifting Your Leadership Higher and Taking Your Team with You...`
  },
  {
    copy: `As Managing Principal for Everest in Europe, we hired IMC to generate sales opportunities for Everest by cold calling business executives and testing their interest in receiving research and taking a meeting with Everest. They were was very successful in generating interest and getting us many meetings with companies at senior management level, leading over time to numerous valuable new clients.`
  },
  {
    copy: `For some time now, IMC have been collaborating and delivering successful CXO engagement projects across a variety of industries. 

    In that time they have demonstrated clear understanding of our strategic objectives and have been a great asset to help us meeting these.`,
    heading: `Senior Vice President, Head of UK/Europe, EXL`
  },
  {
    copy: `I cannot recommend IMC highly enough. If you want good quality leads to the right people in your industry sector then use Ian and his company. IMC's work has lead directly to new business for TCS. On top of that they are a great business to work with. `,
    heading: `SI Specialist Sales Executive - SAP`
  },
  {
    copy: `IMC have provided absolutely fantastic services when I employed them. Their ability to establish contacts with the right person in prospective customer organisations is amazing. The benefits to both businesses were huge, and saved a considerable amount of our time. The return on the fees invested in both cases was many times the the investment. Ian is also a really nice chap, which makes any business relationship with him all the more worth while. `,
    heading: `Project Manager at United Biscuits`
  },
  {
    copy: `I employed IMC in the area of Knowledge Management, and as a service provider to support Major Account Sales, Utilities for Tata Consultancy Service (TCS) one of the worlds largest and best known offshore development IT solutions providers.

    They are diligent and creative in their approach to assignments which reflects the value and integrity of their deserved reputation. `,
    heading: `IT Services and Offshore Business Development`
  },
  {
    copy: `The IMC approach is subtle, well planned and consultative, resulting in much better quality new business opportunities particularly for more complex/higher value propositions.

    - Andy Parker - Managing Director, Sales & Marketing`,
    heading: `Managing Director, Sales & Marketing`
  }
];
