export default function HowWeWork() {
  return (
    <div className='overflow-hidden bg-gray-50 py-16 text-left'>
      <div className='mx-auto max-w-7xl space-y-8 px-4 sm:px-6 lg:px-8'>
        <div className='mx-auto max-w-prose text-base lg:max-w-none'>
          <h2 className='text-lg font-semibold text-red-600'>IMC</h2>
          <p className='mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl'>
            How we work
          </p>
        </div>
        <div className='relative z-10 mx-auto max-w-prose text-base lg:mx-0 lg:max-w-5xl lg:pr-72'>
          <div className='border-b border-gray-200'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
              First Steps
            </h3>
          </div>
          <p className='text-lg text-gray-500 pt-4'>
            We begin by understanding your business, service offerings and
            unique selling points in the same way that your sales and marketing
            people do. We then drill down into your knowledge base to develop
            compelling messages that are tailored to the most appropriate
            targets and decision makers.
          </p>
        </div>
        <div className='lg:grid lg:grid-cols-2 lg:items-start lg:gap-8'>
          <div className='relative z-10'>
            <div className='prose prose-red mx-auto text-gray-500 lg:max-w-none'>
              <div className='border-b border-gray-200'>
                <h3 className='text-lg font-medium leading-6 text-gray-900'>
                  Research
                </h3>
              </div>
              <p>
                We share data cooperatively with our clients and undertake
                research where appropriate to identify the key stakeholders
                within your target sectors both vertically and horizontally.
              </p>
              <div className='border-b border-gray-200'>
                <h3 className='text-lg font-medium leading-6 text-gray-900 pt-8'>
                  Our Approach
                </h3>
              </div>

              <p>
                The way that we work for our clients is indistinguishable from
                that of your employees. We present ourselves as part of your
                business, typically as your ‘client relations’ arm. We use your
                letter heads where appropriate, marketing collateral, email
                address and internal knowledge to engage with prospects in a
                totally transparent way. For all intent and purpose, we act as
                our client.
              </p>

              <div className='border-b border-gray-200'>
                <h3 className='text-lg font-medium leading-6 text-gray-900 pt-8'>
                  Solutions for client challenges
                </h3>
              </div>

              <p>
                Almost without exception, sales teams are governed by the 80/20
                rule. All sales teams have their top performers who account for
                roughly 20% of the headcount but provide 80% of the revenue. The
                challenge is to drive more revenue from the remaining 80% of the
                headcount. This comes down to developing a healthy sales
                pipeline of convertible prospects. This is what we do for a
                typical client. We love prospecting and work tirelessly,
                passionately and diligently to address the prospect pipeline
                shortfall of the sales team and therefore deliver a positive
                impact on our clients’ business growth objectives
              </p>
              <p>
                New starters often negotiate a guaranteed commission element for
                an agreed timeframe. It is important to get these people selling
                to potential clients at the earliest possible opportunity. We
                ensure that new starters are busy, motivated and involved in the
                sales process to ensure their success and that of our clients.
              </p>
              <p>
                Even top salespeople can be confronted with the feast/famine
                syndrome where large sales bids are involved. The amount of time
                consumed in winning a major contract sometimes results in a
                decline of their sales pipeline when the deal has been struck.
                We address these challenges by ensuring that new opportunities
                are available to keep them focused on future wins.
              </p>
              <p>
                n the professional services sector, negotiations are often
                handled by highly knowledgeable individuals who don’t come from
                a new business sales background. They are sometimes either
                uncomfortable or lack the skills to gain face to face selling
                opportunities with new clients. We work to assist these
                professionals to achieve their new business goals.
              </p>
            </div>
            <div className='mx-auto mt-10 flex max-w-prose text-base lg:max-w-none'>
              <div className='rounded-md shadow'>
                <a
                  href='mailto:ian.carr@imcpartnership.co.uk'
                  className='flex w-full items-center justify-center rounded-md border border-transparent bg-red-600 px-5 py-3 text-base font-medium text-white hover:bg-red-700'
                >
                  Contact sales
                </a>
              </div>
              <div className='ml-4 rounded-md shadow'>
                <a
                  href='/how-we-work'
                  className='flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-red-600 hover:bg-gray-50'
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
          <div className='relative mx-auto mt-12 max-w-prose text-base lg:mt-0 lg:max-w-none'>
            <svg
              className='absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20'
              width={404}
              height={384}
              fill='none'
              viewBox='0 0 404 384'
              aria-hidden='true'
            >
              <defs>
                <pattern
                  id='bedc54bc-7371-44a2-a2bc-dc68d819ae60'
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits='userSpaceOnUse'
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className='text-gray-200'
                    fill='currentColor'
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill='url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)'
              />
            </svg>
            <blockquote className='relative rounded-lg bg-white shadow-lg'>
              <div className='rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8'>
                <div className='relative mt-8 text-lg font-medium text-gray-700'>
                  <svg
                    className='absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-gray-200'
                    fill='currentColor'
                    viewBox='0 0 32 32'
                    aria-hidden='true'
                  >
                    <path d='M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z' />
                  </svg>
                  <p className='relative'>
                    There are very few people I have met who have the ability to
                    summon enthusiasm in other people for future meetings the
                    way that IMC can. They really get what their clients are
                    about and how to communicate their sales message to
                    prospects in a professional, ethical and effective way. IMC
                    are a delight to work with and the best in the business.
                  </p>
                </div>
              </div>
              <cite className='relative flex items-center rounded-b-lg bg-red-600 py-5 px-6 not-italic sm:mt-10 sm:items-start sm:py-5 sm:pl-12 sm:pr-10'>
                <span className='relative rounded-full border-2 border-white sm:absolute sm:top-0 sm:-translate-y-1/2 sm:transform'>
                  <img
                    className='h-12 w-12 rounded-full bg-red-300 sm:h-20 sm:w-20'
                    src='https://images.unsplash.com/photo-1500917293891-ef795e70e1f6?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.5&w=160&h=160&q=80'
                    alt=''
                  />
                </span>
                <span className='relative ml-4 font-semibold leading-6 text-red-300 sm:ml-24 sm:pl-1'>
                  <span className='font-semibold text-white sm:inline'>
                    Sophie Bennett
                  </span>{' '}
                  <span className='sm:inline'>
                    Mentor for Driven Entrepreneurs
                  </span>
                </span>
              </cite>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  );
}
