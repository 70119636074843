export default function Example() {
  return (
    <div className='bg-white'>
      <main>
        <div>
          {/* Hero card */}
          <div className='relative pt-8'>
            <div className='absolute inset-x-0 bottom-0 h-1/2 bg-gray-100' />
            <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
              <div className='relative shadow-xl sm:overflow-hidden sm:rounded-2xl'>
                <div className='absolute inset-0'>
                  <img
                    className='h-full w-full object-cover'
                    src='https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100'
                    alt='People working on laptops'
                  />
                  <div className='absolute inset-0 bg-red-600 mix-blend-multiply' />
                </div>
                <div className='relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8'>
                  <h1 className='text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl'>
                    <span className='block text-white'>No ordinary</span>
                    <span className='block text-red-200'>
                      Communications Company..
                    </span>
                  </h1>
                  <p className='mx-auto mt-6 max-w-lg text-center text-xl text-red-200 sm:max-w-3xl'>
                    IMC are a specialist team of business development
                    consultants who have been delivering sales opportunities to
                    the world’s leading Professional Services organisations for
                    over 15 years. We work with leading technology providers,
                    consultancies, professional service organisations, law firms
                    and other specialist business to business sales teams.
                  </p>
                  <div className='mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center'>
                    <div className='space-y-4 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5 sm:space-y-0'>
                      <a
                        href='/how-we-work'
                        className='flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-red-700 shadow-sm hover:bg-red-50 sm:px-8'
                      >
                        Get started
                      </a>
                      <a
                        href='mailto:ian.carr@imcpartnership.co.uk'
                        className='flex items-center justify-center rounded-md border border-transparent bg-red-500 bg-opacity-60 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-opacity-70 sm:px-8'
                      >
                        Contact Us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Logo cloud */}
          <div className='bg-gray-100'>
            <div className='mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8 bg-white'>
              <p className='text-center text-base font-semibold text-gray-500'></p>
              <div className='mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5'>
                <div className='col-span-1 flex justify-center md:col-span-2 lg:col-span-1'>
                  <img
                    className='h-18 aspect-[3/2] object-contain'
                    src='/static/images/logos/3di-logo.png'
                    alt='Tuple'
                  />
                </div>
                <div className='col-span-1 flex justify-center md:col-span-2 lg:col-span-1'>
                  <img
                    className='h-18 aspect-[3/2] object-contain'
                    src='/static/images/logos/accenture-logo.png'
                    alt='Mirage'
                  />
                </div>
                <div className='col-span-1 flex justify-center md:col-span-2 lg:col-span-1'>
                  <img
                    className='h-18 aspect-[3/2] object-contain'
                    src='/static/images/logos/brown-logo.png'
                    alt='StaticKit'
                  />
                </div>
                <div className='col-span-1 flex justify-center md:col-span-2 md:col-start-2 lg:col-span-1'>
                  <img
                    className='h-18 aspect-[3/2] object-contain'
                    src='/static/images/logos/csc-logo.png'
                    alt='Transistor'
                  />
                </div>
                <div className='col-span-2 flex justify-center md:col-span-2 md:col-start-4 lg:col-span-1'>
                  <img
                    className='h-18 aspect-[3/2] object-contain'
                    src='/static/images/logos/engin-logo.png'
                    alt='Workcation'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* More main page content here... */}
      </main>
    </div>
  );
}
