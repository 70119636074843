import FooterLinks from './FooterLinks';

const Footer = () => {
  return (
    <>
      <FooterLinks />
    </>
  );
};

export default Footer;
