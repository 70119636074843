import React from 'react';
import Testimonials from '../components/Testimonials';

export default function testimonials() {
  return (
    <div>
      <Testimonials />
    </div>
  );
}
