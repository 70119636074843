import Features from '../components/Features';
import Hero from '../components/Hero';

const Homepage = () => {
  return (
    <>
      <Hero />
      <Features />
    </>
  );
};

export default Homepage;
